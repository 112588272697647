.ui-skip-navigation {
    position: absolute;
	left: -1000px;
	top: 0;
}

.ui-skip-navigation .link {
    position: absolute;
	top: 0;
    left: 0;
    white-space: nowrap;
	padding: 5px 15px;
	z-index: 9999;
	text-align: center;
	background: var(--common-white);
    -webkit-transition:  left 0.5s ease-out;
    transition: left 0.5s ease-out;
    border-bottom: 1px solid var(--grey-300);
    border-right: 1px solid var(--grey-300);
}

.ui-skip-navigation .link:focus,
.ui-skip-navigation .link:active {
	left: 1000px;
}